import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const textRegEx = /.+/; // One or more characters

interface CallbackProps {
  text: string;
  textError: boolean;
}

/**
 * The component properties
 */
interface TextInputProps {
  /** Hint for browser form autofill feature */
  readonly autocomplete: string;
  /** Optional callback function to call on input value change */
  readonly callback?: (value: CallbackProps) => void;
  /** The default value of the input */
  readonly default?: string;
  /** The id attribute for the input element */
  readonly id: string;
  /** The label for the input element */
  readonly label: string;
  /** The name attribute for the input element */
  readonly name: string;
  /** A regex pattern to use for validation @default /.+/ */
  readonly regex?: RegExp;
}

/**
 * The component state
 */
interface TextInputState {
  /** A regex pattern to use for validation */
  regex: RegExp;
  /** The current input value */
  text: string;
  /** Whether there's an error with the current input */
  textError: boolean | null;
}

class TextInput extends React.Component<TextInputProps, TextInputState> {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.default || '',
      textError: this.props.default ? false : null,
      regex: this.props.regex || textRegEx
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.validateText = this.validateText.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    let prevWasNotEmpty =
      prevProps.default !== '' && prevProps.default !== null;
    let currentIsNowEmpty =
      this.props.default === '' || this.props.default === null;
    if (prevWasNotEmpty && currentIsNowEmpty) {
      this.handleTextChange({ target: { value: '' } });
    }
  }
  handleTextChange(e) {
    let value = e.target.value; // New text
    let isCurrentError = this.state.textError; // Current text
    let isNewValid = this.isValidText(value);
    this.setState({ text: value });
    if (isNewValid || (isCurrentError === false && !isNewValid)) {
      // Update error when text is now valid or now invalid
      this.setState({ textError: !isNewValid });
    }
    if (this.props.callback) {
      this.props.callback({ text: value, textError: !isNewValid });
    }
  }
  isValidText(text) {
    return text.match(this.state.regex) !== null;
  }
  validateText(e) {
    this.setState({
      textError: !this.isValidText(this.state.text)
    });
  }
  render() {
    return (
      <div>
        <label id={this.props.id + '-label'} htmlFor={this.props.id}>
          {this.props.label}
        </label>
        {this.state.textError !== null && (
          <span
            id={this.props.id + '-status'}
            className="red-text ml-10 input-validation"
          >
            {this.state.textError ? (
              'Please enter a valid ' + this.props.name.replace(/-/g, ' ') + '.'
            ) : (
              <FontAwesomeIcon className="green-text" icon={faCheck} />
            )}
          </span>
        )}
        <input
          id={this.props.id}
          name={this.props.name}
          type="text"
          required
          onChange={this.handleTextChange}
          onBlur={this.validateText}
          autoComplete={this.props.autocomplete}
          value={this.state.text}
        />
      </div>
    );
  }
}

export default TextInput;
