import React, { useEffect } from 'react';
import BlogPost from '../../components/BlogPost';
import {
  scrollToTop,
  updateMetadata,
  trackCurrentPageView
} from '../../GlobalFunctions';

function TopFeaturedItemsAugust2021() {
  const TITLE = 'Top Featured Menu Items';
  const DESCRIPTION =
    'The top featured menu items selected by users in this calculator in April 2024.';
  const IMAGE = 'https://www.hgnutrition.com/images/ognoodles.jpg';
  const CREATED = '2024-04-30T20:00:00-5:00';
  const MODIFIED = '2024-04-27T20:00:00-5:00';
  const IS_BLOG = true;

  useEffect(() => {
    updateMetadata(
      TITLE,
      DESCRIPTION,
      IMAGE,
      'index',
      CREATED,
      MODIFIED,
      IS_BLOG
    );
    scrollToTop();
    trackCurrentPageView();
  });

  return (
    <BlogPost title="Top Featured Menu Items" timeframe="April 2024">
      <p>
        Honeygrow currates a selection of featured meals. Here are the customer
        favorites from the calculator:
      </p>
      <div className="flex flex-wrap">
        <div className="flex-item">
          <h2>Top Featured Salads</h2>
          <ol>
            <li>Cobb</li>
            <li>Greek Out W/ Chicken</li>
            <li>Kale Chicken Caesar</li>
            <li>Make It Grain W/ Chicken</li>
            <li>Walnut Street Tofu & Noodle</li>
            <li>Whole Salad</li>
            <li>Paleo Salad</li>
          </ol>
        </div>
        <div className="flex-item">
          <h2>Top Featured Stirfries</h2>
          <ol>
            <li>Spicy Garlic</li>
            <li>Red Coconut Curry</li>
            <li>Sweet Soy Five Spice</li>
            <li>Sesame Garlic</li>
            <li>Chesapeake Crab</li>
            <li>Chicken Jawn</li>
            <li>The Vegan Sesame Garlic</li>
          </ol>
        </div>
        <div className="flex-item">
          <h2>Top Featured Honeybar</h2>
          <ol>
            <li>Cobbler</li>
            <li>Fruit + Granola</li>
            <li>Apple Pie</li>
            <li>Brownie Crumble</li>
          </ol>
        </div>
        <div className="flex-item">
          <h2>Top Sides</h2>
          <ol>
            <li>Herbed Focaccia</li>
            <li>Brownies</li>
            <li>Kids Mac N Cheese</li>
          </ol>
        </div>
      </div>
    </BlogPost>
  );
}

export default TopFeaturedItemsAugust2021;
