import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Auth, User, getAuth } from 'firebase/auth';
import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { trackError, trackLink } from '../GlobalFunctions';
import { FirebaseApp } from 'firebase/app';

/**
 * The component properties
 */
interface NavProps {
  /** The firebase app */
  readonly firebase: FirebaseApp;
  /** Whether the app is loaded */
  readonly loaded: boolean;
  /** The current menu name */
  readonly menuseason: string;
  /** Whether menus should be displayed */
  readonly showMenus: boolean;
  /** Whether the current user is signed in */
  readonly signedIn: boolean;
  /** Method to call to update the current season */
  readonly updateSeason: (event: ChangeEvent<HTMLSelectElement>) => void;
  /** Method to call to update the currently signed in user */
  readonly updateSignIn: (user: User | null) => void;
  /** The currently authenticated user */
  readonly user: User | null;
}

/**
 * The component state
 */
interface NavState {
  /** Whether account options should be displayed */
  showAcctOptions: boolean;
}

class Nav extends React.Component<NavProps, NavState> {
  readonly auth: Auth;
  constructor(props) {
    super(props);
    this.state = {
      showAcctOptions: false
    };
    this.logOut = this.logOut.bind(this);
    this.toggleAcctOptions = this.toggleAcctOptions.bind(this);
    this.auth = getAuth(this.props.firebase);
  }
  logOut() {
    let cmp = this;
    this.auth
      .signOut()
      .then(function () {
        cmp.props.updateSignIn(null);
      })
      .catch(function (error) {
        console.error(error);
        trackError('Log Out', 'Log out error: ' + error.message);
      });
    trackLink('Nav', 'Log Out');
  }
  toggleAcctOptions() {
    this.setState({
      showAcctOptions: !this.state.showAcctOptions
    });
  }
  render() {
    return (
      <nav className="nav">
        {this.props.loaded && (
          <div className="content-container container-padding">
            <div className="title">
              <Link
                className="title-link"
                onClick={() => trackLink('Nav', 'Main Home')}
                to="/"
              >
                Honeygrow Nutrition Calculator
              </Link>
            </div>
            {this.props.showMenus && (
              <div className="date">
                <select
                  id="menu-season-select"
                  className="no-style"
                  onChange={(e) => {
                    this.props.updateSeason(e);
                  }}
                  value={this.props.menuseason}
                >
                  <option value="spring-2024">Spring 2024</option>
                  <option value="summer-2022">Summer 2022</option>
                  <option value="winter-2022">Winter 2022</option>
                  <option value="summer-2021">Summer 2021</option>
                  <option value="may-2021">Spring 2021</option>
                  <option value="summer-2020">Summer 2020</option>
                  <option value="spring-2020">Spring 2020</option>
                  <option value="winter-2020">Winter 2020</option>
                  <option value="fall-2019">Fall 2019</option>
                  <option value="summer-2019">Summer 2019</option>
                  <option value="winter-2019">Winter 2019</option>
                  <option value="fall-2018">Fall 2018</option>
                </select>
              </div>
            )}
            {this.props.signedIn && (
              <div className="account-options flex-col-center">
                <button
                  className="account-toggle flex-col-center"
                  type="button"
                  onClick={this.toggleAcctOptions}
                >
                  {this.props.user?.photoURL ? (
                    <img
                      className="account-avatar"
                      alt={this.props.user.displayName + ' photo'}
                      src={this.props.user.photoURL}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faUserCircle} size="1x" />
                  )}
                </button>
                <ul className="account-options-links">
                  <li className="blue-text">{this.props.user?.displayName}</li>
                  <li>
                    <Link
                      onClick={() => trackLink('Nav', 'Dropdown - Home')}
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => trackLink('Nav', 'Dropdown - Saved Meals')}
                      to="/account/"
                    >
                      Saved Meals
                    </Link>
                  </li>
                  <li>
                    <button onClick={this.logOut}>Log Out</button>
                  </li>
                </ul>
              </div>
            )}
            {!this.props.signedIn && (
              <Link
                onClick={() => trackLink('Nav', 'Log In')}
                className="log-in-out"
                to="/login/"
              >
                Log In
              </Link>
            )}
          </div>
        )}
      </nav>
    );
  }
}

export default Nav;
