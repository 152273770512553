import ReactGA from 'react-ga';

ReactGA.initialize('UA-129395828-1');

const meta = {
  'default-title': 'HG Nutrition',
  home: {
    title: null,
    desc: 'Unofficial nutritional information calculator for Honeygrow. Calculate the nutrition for your favorite meals!',
    image: 'https://www.hgnutrition.com/images/ogimage.jpg',
    index: 'index',
    created: '2018-11-18T20:00:00-5:00',
    modified: '2021-06-23T20:00:00-5:00'
  },
  login: {
    title: 'Log In',
    desc: 'Log in to your HG Nutrition account.',
    image: 'https://www.hgnutrition.com/images/login.jpg',
    index: 'index',
    created: '2020-05-22T20:00:00-5:00',
    modified: '2021-06-21T20:00:00-5:00'
  },
  privacy: {
    title: 'Privacy Policy',
    desc: 'Privacy policy for the HG Nutrition caluclator website.',
    image: 'https://www.hgnutrition.com/images/privacy.jpg',
    index: 'index',
    created: '2020-05-22T20:00:00-5:00',
    modified: null
  },
  account: {
    title: 'My Meals',
    desc: 'View all of your account information and custom meals.',
    image: 'https://www.hgnutrition.com/images/ogimage.jpg',
    index: 'noindex',
    created: '2020-05-22T20:00:00-5:00',
    modified: null
  },
  contact: {
    title: 'Contact',
    desc: 'Contact the developer of the HG Nutrition calculator with any questions, concerns, or general feedback.',
    image: 'https://www.hgnutrition.com/images/contact.jpg',
    index: 'index',
    created: '2020-05-22T20:00:00-5:00',
    modified: '2021-06-21T20:00:00-5:00'
  },
  meal: {
    title: 'My Meal',
    desc: 'Unofficial nutritional information calculator for Honeygrow. Calculate the nutrition for your favorite meals!',
    image: 'https://www.hgnutrition.com/images/ogimage.jpg',
    index: 'noindex',
    created: '2020-05-22T20:00:00-5:00',
    modified: null
  },
  unauthorized: {
    title: 'Unauthorized',
    desc: 'You are not authorized to view this page.',
    image: 'https://www.hgnutrition.com/images/ogimage.jpg',
    index: 'noindex',
    created: '2020-05-22T20:00:00-5:00',
    modified: null
  },
  mealnotfound: {
    title: 'Meal Not Found',
    desc: 'This meal does not exist.',
    image: 'https://www.hgnutrition.com/images/ogimage.jpg',
    index: 'noindex',
    created: '2020-05-22T20:00:00-5:00',
    modified: null
  },
  about: {
    title: 'About',
    desc: 'Everything you need to know about this unofficial Honeygrow nutrition calculator.',
    image: 'https://www.hgnutrition.com/images/ogimage.jpg',
    index: 'index',
    created: '2021-06-23T20:00:00-5:00',
    modified: null
  },
  'top-menu': {
    title: 'Top Menu Items',
    desc: 'The top menu items users love from this nutitrion calculator.',
    image: 'https://www.hgnutrition.com/images/ogimage.jpg',
    index: 'index',
    created: '2021-06-24T20:00:00-5:00',
    modified: '2021-06-24T20:00:00-5:00'
  }
};

// Track a link in GA
export function trackLink(action, label): void {
  ReactGA.event({
    category: 'Clicks',
    action: action,
    label: label
  });
}

export function trackCurrentPageView(): void {
  trackPageView(window.location.pathname);
}

export function trackPageView(path): void {
  ReactGA.pageview(path);
}

// Track an error in GA
export function trackError(action, label): void {
  ReactGA.event({
    category: 'Errors',
    action: action,
    label: label
  });
}
// Track an error in GA
export function trackEvent(action, label): void {
  ReactGA.event({
    category: 'Other',
    action: action,
    label: label
  });
}
// Returns div with error message styling
export function errorMessage(error): HTMLDivElement {
  console.error(error);
  let errors = document.getElementsByClassName('error-text');
  for (let i = 0; i < errors.length; i++) {
    errors[0].remove();
  }
  let div = document.createElement('div');
  div.classList.add('error-text');
  div.classList.add('mt-20');
  div.innerHTML =
    '<p class="red-text">' +
    error.message.replace('The user may have been deleted.', '') +
    '</p>';
  return div;
}
// Creates error message banner that fades out when clicked
export function errorMessageBanner(error): void {
  console.error(error);
  trackEvent('Error Banner', error.message);
  // Create notification that there was an error
  let div = document.createElement('div');
  div.classList.add('notification');
  div.classList.add('error');
  div.innerHTML =
    '<div class="content-container container-padding"><p>' +
    error.message.replace('The user may have been deleted.', '') +
    '</p></div>';
  document.getElementsByTagName('body')[0].appendChild(errorMessage(error));
  // Remove notification only after click
  div.addEventListener('click', () => (div.style.opacity = '0'));
  div.addEventListener('transitionend', () => div.remove());
}
// Creates message banner that fades out after 4 seconds
export function messageBanner(message, ms, isStatic, storageId?): void {
  // Create notification that meal has been saved
  let div = document.createElement('div');
  div.classList.add('notification');
  div.classList.add('saved');
  if (isStatic) {
    div.classList.add('static');
  }
  div.innerHTML =
    '<div class="content-container container-padding"><p>' +
    message +
    '</p></div>';
  let body = document.getElementsByTagName('body')[0];
  body.insertBefore(div, body.querySelector(':first-child'));

  if (ms > 0) {
    setTimeout(() => {
      // Fadeout and remove notification after a few seconds
      div.style.opacity = '0';
      div.addEventListener('transitionend', () => div.remove());
    }, 4000);
  } else {
    div.addEventListener('click', function handler() {
      div.remove();
      localStorage.setItem(storageId, 'true');
      div.removeEventListener('click', handler);
    });
  }
}

export function updatePageMetadata(page): void {
  let metadata = meta[page];
  updateMetadata(
    metadata.title,
    metadata.desc,
    metadata.image,
    metadata.index,
    metadata.created,
    metadata.modified,
    false
  );
}

export function updateMetadata(
  title,
  desc,
  imgUrl,
  robots,
  created?,
  modified?,
  isBlog?
): void {
  if (title != null) {
    document.title = title + ' | ' + meta['default-title'];
  } else {
    document.title = meta['default-title'];
    title = meta['default-title'];
  }
  document
    .querySelector('meta[property="og:title"]')
    ?.setAttribute('content', title);
  document
    .querySelector('meta[name="description"]')
    ?.setAttribute('content', desc);
  document
    .querySelector('meta[property="og:description"]')
    ?.setAttribute('content', desc);
  document
    .querySelector('meta[property="og:url"]')
    ?.setAttribute(
      'content',
      'https://www.hgnutrition.com' + window.location.pathname
    );
  document
    .querySelector('meta[name="robots"]')
    ?.setAttribute('content', robots);
  if (imgUrl) {
    document
      .querySelector('meta[property="og:image"]')
      ?.setAttribute('content', imgUrl);
  }
  updateStructuredData(title, desc, created, modified, isBlog, imgUrl);
}

export function updateStructuredData(
  title,
  desc,
  created,
  modified,
  isBlog,
  imgUrl
): void {
  let data: any = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    name: `${title}`,
    description: `${desc}`,
    dateCreated: `${created}`
  };
  if (isBlog) {
    data['@id'] = window.location.href;
    data['@type'] = 'Article';
    data['headline'] = desc;
    data['datePublished'] = created;
    data['author'] = 'HG Nutrition';
    data['image'] = imgUrl;
    data['publisher'] = {
      '@type': 'Person',
      name: 'HG Nutrition'
    };
  }
  if (modified !== null) {
    data.dateModified = modified;
  }

  let dataElement = document.getElementById('structured-data')!;
  dataElement.innerHTML = JSON.stringify(data);
}

export function scrollToTop(): void {
  setTimeout(function () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
}
