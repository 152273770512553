import { FirebaseApp } from 'firebase/app';
import {
  Auth,
  User,
  createUserWithEmailAndPassword,
  getAuth
} from 'firebase/auth';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  errorMessage,
  scrollToTop,
  trackCurrentPageView,
  trackError,
  trackEvent,
  trackLink,
  updatePageMetadata
} from '../GlobalFunctions';
import LogIn from '../components/LogIn';
import EmailInput from '../components/form/EmailInput';
import PasswordInput from '../components/form/PasswordInput';
import TextInput from '../components/form/TextInput';

/**
 * The component properties
 */
interface LoginPageProps {
  /** The firebase app */
  readonly firebase: FirebaseApp;
  /** The currently authenticated user */
  readonly user: User | null;
}

/**
 * The component state
 */
interface LoginPageState {
  /** Indicates whether the new account creation was successful */
  accountCreated: boolean;
  /** New user email */
  email: string;
  /** Whether there's an error on the current email */
  emailError: boolean;
  /** New user first name */
  firstname: string;
  /** New user last name */
  lastname: string;
  /** New user password */
  password: string;
  /** The current password error message */
  pwError: string;
  /** Whether to render the sign up form */
  showSignUp: boolean;
}

class LogInPage extends React.Component<LoginPageProps, LoginPageState> {
  readonly auth: Auth;
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      showSignUp: false,
      emailError: false,
      pwError: '',
      accountCreated: false
    };
    this.createFirebaseAccount = this.createFirebaseAccount.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.auth = getAuth(props.firebase);
  }
  componentDidMount() {
    updatePageMetadata('login');
    scrollToTop();
    trackCurrentPageView();
  }
  createFirebaseAccount() {
    let cmp = this;
    createUserWithEmailAndPassword(
      this.auth,
      this.state.email,
      this.state.password
    )
      .then(function (data) {
        trackEvent('Login Page', 'Account Created - Email');
        cmp.setState({ accountCreated: true });
      })
      .catch(function (error) {
        console.error(error);
        if (error.code === 'auth/weak-password') {
          cmp.setState({ pwError: error.message });
        } else {
          trackError('Login Page', 'Account Creation Error - ' + error.message);
          document
            .getElementById('firebase-create-login-form')
            ?.appendChild(errorMessage(error));
          document
            .getElementById('create-login-submit')
            ?.removeAttribute('disabled');
        }
      });
  }
  handleEmailChange(emailInput: { email: string, emailError: boolean }) {
    this.setState({ ...emailInput });
  }
  handlePasswordChange(pwInput) {
    this.setState({ password: pwInput.password });
  }
  handleFirstNameChange(textInput) {
    this.setState({ firstname: textInput.text });
  }
  handleLastNameChange(textInput) {
    this.setState({ lastname: textInput.text });
  }
  handleFormSubmit(e) {
    e.preventDefault();
    document
      .getElementById('create-login-submit')
      ?.setAttribute('disabled', 'true');
    this.createFirebaseAccount();
  }
  toggleForm(e) {
    let login = document.getElementsByClassName('account-login')[0];
    let signup = document.getElementsByClassName('account-create')[0];

    if (e.target.classList.contains('account-login-button')) {
      login.classList.add('hidden-on-mobile');
      signup.classList.remove('hidden-on-mobile');
      trackLink('Login Page', 'Mobile switch to sign up');
    } else {
      signup.classList.add('hidden-on-mobile');
      login.classList.remove('hidden-on-mobile');
      trackLink('Login Page', 'Mobile switch to login');
    }
  }
  render() {
    return (
      <div className="content-container container-padding">
        <h1 className="mb-10">Account Log In</h1>
        <div className="login-page">
          <div className="account-create hidden-on-mobile">
            <div className="intro">
              <h2>Create Account</h2>
              <p>
                By creating a HG Nutrition account, you can create and save
                meals!
              </p>
            </div>
            <div id="firebase-create-login">
              {!this.state.accountCreated && (
                <div>
                  <form
                    id="firebase-create-login-form"
                    onSubmit={this.handleFormSubmit}
                  >
                    <TextInput
                      callback={this.handleFirstNameChange}
                      id="create-login-firstname"
                      name="first-name"
                      autocomplete="given-name"
                      label="First Name"
                    />
                    <TextInput
                      callback={this.handleLastNameChange}
                      id="create-login-lastname"
                      name="last-name"
                      autocomplete="family-name"
                      label="Last Name"
                    />
                    <EmailInput
                      callback={this.handleEmailChange}
                      id="create-login-email"
                      nextFocus="create-login-submit"
                    />
                    <PasswordInput
                      callback={this.handlePasswordChange}
                      id="create-login-password"
                      name="password"
                      autocomplete="new-password"
                      label="New Password"
                      errorMessage={this.state.pwError}
                    />
                    <div className="center mt-20">
                      <button
                        id="create-login-submit"
                        disabled={!this.props.user?.uid ? undefined : true}
                        type="submit"
                        className="btn btn-alt"
                      >
                        Create Account
                      </button>
                    </div>
                  </form>
                  <div className="mobile-only">
                    <p className="mb-5 mt-40">Already have an account?</p>
                    <button
                      className="account-create-button btn btn-primary"
                      type="button"
                      onClick={this.toggleForm}
                    >
                      Log In
                    </button>
                  </div>
                </div>
              )}
              {this.state.accountCreated && (
                <div className="account-created vertical-center center">
                  <p className="blue-text">Account created!</p>
                  <div>
                    <Link
                      onClick={() => trackLink('Account Created', 'Home')}
                      style={{ width: 'auto' }}
                      className="btn btn-alt mb-10"
                      to="/"
                    >
                      Create Your First Meal
                    </Link>
                    <br />
                    <Link
                      onClick={() =>
                        trackLink('Account Created', 'View Account')
                      }
                      style={{ width: 'auto' }}
                      className="btn btn-alt"
                      to="/account/"
                    >
                      View Account
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="account-login">
            <div className="intro">
              <h2>Log In</h2>
              <p>Log in to your existing HG Nutrition account.</p>
            </div>
            <LogIn
              isLoggedOut={
                this.props.user === null || this.props.user.uid === null
              }
              firebase={this.props.firebase}
              user={this.props.user}
            />
            <div className="mobile-only">
              <p className="mb-5 mt-40">Don't have an account?</p>
              <button
                className="account-login-button btn btn-primary"
                type="button"
                onClick={this.toggleForm}
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogInPage;
