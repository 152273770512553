import React from 'react';
import AnimateHeight from 'react-animate-height';
import { MealNutrition } from '../models';

/**
 * The component properties
 */
interface MobileTotalsProps {
  /** A method to call on reset */
  readonly buttonclick: () => void;
  /** Whether there are preselected menu items */
  readonly preselectedItems: boolean;
  /** A method to call to save the current meal */
  readonly saveExistingMeal: () => void;
  /** Whether to display the save button */
  readonly showSaveBtn: boolean;
  /** A method that toggles the save menu */
  readonly toggleSaveMenu: () => void;
  /** Nutritional value totals for the current meal */
  readonly totals: MealNutrition;
}

/**
 * The component state
 */
interface MobileTotalsState {
  /** The CSS height of the element for the accordion animation */
  height: 0 | 'auto';
  /** The font awesome icon to display */
  icon: 'fas fa-angle-up' | 'fas fa-angle-down';
}

class MobileTotals extends React.Component<
  MobileTotalsProps,
  MobileTotalsState
> {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
      icon: 'fas fa-angle-up'
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
  }

  // Toggle accordion after click
  toggleAccordion(e) {
    // Prevent default link action
    e.preventDefault();

    // Toggle accordion height and font awesome icon
    this.setState({
      height: this.state.height === 0 ? 'auto' : 0,
      icon: this.state.height === 0 ? 'fas fa-angle-down' : 'fas fa-angle-up'
    });
  }
  render() {
    return (
      <tr>
        <td>
          <button
            className="btn-small btn-primary reset-button"
            aria-label="Reset nutrition"
            onClick={this.props.buttonclick}
          >
            Reset
          </button>
        </td>
        <td>
          {!this.props.preselectedItems && this.props.showSaveBtn ? (
            <button
              className="btn-small btn-alt"
              onClick={this.props.toggleSaveMenu}
            >
              Save
            </button>
          ) : null}
          {this.props.preselectedItems && this.props.showSaveBtn ? (
            <button
              className="btn-small btn-alt"
              onClick={this.props.saveExistingMeal}
            >
              Save
            </button>
          ) : null}
        </td>
        <td onClick={(e) => this.toggleAccordion(e)}>
          <button className="more-info" key="final-counts-mobile">
            <i className={this.state.icon}></i>
          </button>
          <div>
            Total Calories:{' '}
            <span className="total-value" id="totalCal">{this.props.totals.totalCal}</span>
          </div>
          <AnimateHeight duration={500} height={this.state.height}>
            <div className="accordion">
              <p>
                <strong>Calories from fat:</strong>{' '}
                <span className="total-value" id="totalFatCal">{this.props.totals.totalFatCal}</span>
              </p>
              <p>
                <strong>Fat (g):</strong>{' '}
                <span className="total-value" id="totalFat">{this.props.totals.totalFat}</span>
              </p>
              <p>
                <strong>Saturated fat (g):</strong>{' '}
                <span className="total-value" id="totalSatFat">{this.props.totals.totalSatFat}</span>
              </p>
              <p>
                <strong>Trans fat (g):</strong>{' '}
                <span className="total-value" id="totalTransFat">
                  {this.props.totals.totalTransFat}
                </span>
              </p>
              <p>
                <strong>Cholesterol (mg):</strong>{' '}
                <span className="total-value" id="totalChol">{this.props.totals.totalChol}</span>
              </p>
              <p>
                <strong>Sodium (mg):</strong>{' '}
                <span className="total-value" id="totalSodium">{this.props.totals.totalSodium}</span>
              </p>
              <p>
                <strong>Carbohydrates (g):</strong>{' '}
                <span className="total-value" id="totalCarbs">{this.props.totals.totalCarbs}</span>
              </p>
              <p>
                <strong>Sugar (g):</strong>{' '}
                <span className="total-value" id="totalSugar">{this.props.totals.totalSugar}</span>
              </p>
              <p>
                <strong>Dietary fiber (g):</strong>{' '}
                <span className="total-value" id="totalFiber">{this.props.totals.totalFiber}</span>
              </p>
              <p>
                <strong>Protein (g):</strong>{' '}
                <span className="total-value" id="totalProtein">{this.props.totals.totalProtein}</span>
              </p>
            </div>
          </AnimateHeight>
        </td>
      </tr>
    );
  }
}

export default MobileTotals;
