import React from 'react';
import AnimateHeight from 'react-animate-height';
import { trackLink } from '../GlobalFunctions';
import { MenuItem as MenuItemObj } from '../models';

/**
 * The component properties
 */
interface MenuItemProps {
  /** The current menu item */
  readonly item: MenuItemObj;
  /** Whether the current viewport is mobile */
  readonly mobile: boolean;
  /** Reset function */
  readonly resetFunct: () => void;
  /** Whether the component should be reset */
  readonly resetState: boolean;
  /** A method to update the current nutritional totals */
  readonly update: (itemId: string, selected: boolean) => void;
}

/**
 * The component state
 */
interface MenuItemState {
  /** Whether the current menu item is selected */
  isSelected: boolean;
  /** The id of the current menu item */
  itemId: string;
  /** The height of the element */
  height: 'auto' | 0;
}

class MenuItem extends React.Component<MenuItemProps, MenuItemState> {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      itemId: this.props.item.id,
      height: 0
    };
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.toggleSelection = this.toggleSelection.bind(this);
  }
  static getDerivedStateFromProps(props, s) {
    let state = s;
    // Deselect menu items when reset button is clicked
    if (props.resetState) {
      state.isSelected = false;
      props.resetFunct();
    }
    return state;
  }
  // Prevents default link event
  handleLinkClick(e) {
    e.preventDefault();
    this.toggleSelection();
  }
  toggleSelection() {
    // Toggle selected state
    this.setState({
      isSelected: !this.state.isSelected
    });
    // Track item click in GA based on whether selected or deselected
    if (!this.state.isSelected) {
      trackLink(
        'Select item',
        this.props.item.name + ' - ' + this.props.item.category
      );
    } else {
      trackLink(
        'Deselect Item',
        this.props.item.name + ' - ' + this.props.item.category
      );
    }
    // Call parent function to update total nutrition
    this.props.update(this.props.item.id, this.state.isSelected);
  }
  toggleAccordion(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      height: this.state.height === 0 ? 'auto' : 0
    });

    // Track open accordion click in GA
    if (this.state.height === 0) {
      trackLink(
        'View Nutrition',
        this.props.item.name + ' - ' + this.props.item.category
      );
    }
  }
  render() {
    let allergies = (
      <div className="contains-marker-container">
        {this.props.item.egg ? (
          <span title="Contains Egg" className="contains-marker contains-egg">
            <span className="off-screen">Contains Egg</span>
          </span>
        ) : (
          ''
        )}
        {this.props.item.milk ? (
          <span title="Contains Milk" className="contains-marker contains-milk">
            <span className="off-screen">Contains Milk</span>
          </span>
        ) : (
          ''
        )}
        {this.props.item.shellfish ? (
          <span
            title="Contains Shellfish"
            className="contains-marker contains-shellfish"
          >
            <span className="off-screen">Contains Shellfish</span>
          </span>
        ) : (
          ''
        )}
        {this.props.item.soy ? (
          <span title="Contains Soy" className="contains-marker contains-soy">
            <span className="off-screen">Contains Soy</span>
          </span>
        ) : (
          ''
        )}
        {this.props.item.tree ? (
          <span
            title="Contains Tree Nuts"
            className="contains-marker contains-tree"
          >
            <span className="off-screen">Contains Tree Nuts</span>
          </span>
        ) : (
          ''
        )}
        {this.props.item.wheat ? (
          <span
            title="Contains Wheat"
            className="contains-marker contains-wheat"
          >
            <span className="off-screen">Contains Wheat</span>
          </span>
        ) : (
          ''
        )}
      </div>
    );
    return (
      <tr key={this.props.item.id} onClick={this.toggleSelection}>
        <td>
          <button
            id={this.props.item.id}
            className={
              this.state.isSelected ? 'menu-select selected' : 'menu-select'
            }
            title={
              this.state.isSelected
                ? 'Remove ' + this.props.item.name + ' from Total'
                : 'Add ' + this.props.item.name + ' to Total'
            }
            onClick={this.handleLinkClick}
          >
            <i className="fas fa-plus"></i>
          </button>
        </td>
        {this.props.mobile && (
          <td>
            <button
              className="more-info"
              key={this.props.item.id}
              onClick={(e) => this.toggleAccordion(e)}
            >
              <i className="fas fa-info-circle"></i>
            </button>
            <div className="mobile-item-name">
              <span className="name">{this.props.item.name}</span> {allergies}
            </div>
            <AnimateHeight duration={500} height={this.state.height}>
              <div className="accordion">
                <p>
                  <strong>Calories:</strong> {this.props.item.cals}
                </p>
                <p>
                  <strong>Calories from fat:</strong> {this.props.item.fatCal}
                </p>
                <p>
                  <strong>Fat (g):</strong> {this.props.item.fat}
                </p>
                <p>
                  <strong>Saturated fat (g):</strong> {this.props.item.satFat}
                </p>
                <p>
                  <strong>Trans fat (g):</strong> {this.props.item.transFat}
                </p>
                <p>
                  <strong>Cholesterol (mg):</strong>{' '}
                  {this.props.item.cholesterol}
                </p>
                <p>
                  <strong>Sodium (mg):</strong> {this.props.item.sodium}
                </p>
                <p>
                  <strong>Carbohydrates (g):</strong> {this.props.item.carbs}
                </p>
                <p>
                  <strong>Sugar (g):</strong> {this.props.item.sugar}
                </p>
                <p>
                  <strong>Dietary fiber (g):</strong> {this.props.item.fiber}
                </p>
                <p>
                  <strong>Protein (g):</strong> {this.props.item.protein}
                </p>
              </div>
            </AnimateHeight>
          </td>
        )}
        {!this.props.mobile && (
          <td>
            {this.props.item.name} <br />
            {allergies}
          </td>
        )}
        {!this.props.mobile && <td>{this.props.item.cals}</td>}
        {!this.props.mobile && <td>{this.props.item.fatCal}</td>}
        {!this.props.mobile && <td>{this.props.item.fat}</td>}
        {!this.props.mobile && <td>{this.props.item.satFat}</td>}
        {!this.props.mobile && <td>{this.props.item.transFat}</td>}
        {!this.props.mobile && <td>{this.props.item.cholesterol}</td>}
        {!this.props.mobile && <td>{this.props.item.sodium}</td>}
        {!this.props.mobile && <td>{this.props.item.carbs}</td>}
        {!this.props.mobile && <td>{this.props.item.fiber}</td>}
        {!this.props.mobile && <td>{this.props.item.sugar}</td>}
        {!this.props.mobile && <td>{this.props.item.protein}</td>}
      </tr>
    );
  }
}

export default MenuItem;
