import { initializeApp } from 'firebase/app';
import { User, getAuth } from 'firebase/auth';
import React from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch
} from 'react-router-dom';
import * as Constants from './Constants';
import { trackLink } from './GlobalFunctions';
import { Footer } from './components/Footer';
import Nav from './components/Nav';
import { firebaseConfig } from './config/config';
import AboutPage from './pages/AboutPage';
import AccountPage from './pages/AccountPage';
import ContactMe from './pages/ContactMe';
import LogInPage from './pages/LogInPage';
import MealPage from './pages/MealPage';
import NutritionPage from './pages/NutritionPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import BlogLandingPage from './pages/blog-posts/BlogLandingPage';
import TopFeaturedItemsAugust2021 from './pages/blog-posts/TopFeaturedItemsAugust2021';
import TopHoneybarItemsAugust2021 from './pages/blog-posts/TopHoneybarItemsAugust2021';
import TopSaladItemsAugust2021 from './pages/blog-posts/TopSaladItemsAugust2021';
import TopStirfryItemsAugust2021 from './pages/blog-posts/TopStirfryItemsAugust2021';
import './style.scss';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

/**
 * Represents the component's state
 */
interface AppState {
  loggedIn: boolean | null;
  user: User | null;
  menuseason: string;
  itemIsSelected: boolean;
  firstLoad: boolean;
}

/**
 * The base application component
 */
class App extends React.Component<{}, AppState> {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: null,
      user: null,
      menuseason: 'spring-2024',
      itemIsSelected: false,
      firstLoad: false
    };
    this.updateSeason = this.updateSeason.bind(this);
    this.updateSignIn = this.updateSignIn.bind(this);
  }
  componentDidMount() {
    // Remove no-script class from body on page load
    document.getElementsByTagName('body')[0].className = 'active no-outline';
    let cmp = this;
    auth.onAuthStateChanged((user) => {
      cmp.updateSignIn(user);
    });
  }
  updateSeason(event) {
    trackLink('Update Season', event.target.value);
    this.setState({
      menuseason: event.target.value
    });
  }
  updateSignIn(user) {
    let isLoggedIn = user !== null;
    this.setState({
      loggedIn: isLoggedIn,
      user: user,
      firstLoad: true
    });
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/">
            <Nav
              showMenus={true}
              updateSignIn={this.updateSignIn}
              loaded={this.state.firstLoad}
              signedIn={this.state.loggedIn}
              updateSeason={this.updateSeason}
              menuseason={this.state.menuseason}
              user={this.state.user}
              firebase={app}
            />
          </Route>
          <Route path="/*">
            <Nav
              showMenus={false}
              updateSignIn={this.updateSignIn}
              loaded={this.state.firstLoad}
              signedIn={this.state.loggedIn}
              updateSeason={this.updateSeason}
              menuseason={this.state.menuseason}
              user={this.state.user}
              firebase={app}
            />
          </Route>
        </Switch>
        <Switch>
          <Route exact path="/login">
            <LogInPage user={this.state.user} firebase={app} />
          </Route>
          <Route exact path="/account">
            {this.state.loggedIn === true && (
              <AccountPage firebase={app} user={this.state.user} />
            )}
            {this.state.loggedIn === false && <Redirect to="/" />}
          </Route>
          <Route exact path="/meal/:mealId">
            {this.state.loggedIn !== null && (
              <MealPage
                firebase={app}
                user={this.state.user}
                menuseason={this.state.menuseason}
              />
            )}
          </Route>
          <Route exact path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/contact">
            {this.state.loggedIn !== null && (
              <ContactMe user={this.state.user} />
            )}
          </Route>
          <Route exact path="/about">
            <AboutPage />
          </Route>
          <Route exact path="/">
            <NutritionPage
              firebase={app}
              user={this.state.user}
              menuseason={this.state.menuseason}
            />
          </Route>
          <Route exact path={Constants.TOP_MENU_ROUTE}>
            <BlogLandingPage />
          </Route>
          <Route exact path={Constants.SALAD_ROUTE}>
            <TopSaladItemsAugust2021 />
          </Route>
          <Route exact path={Constants.STIRFRY_ROUTE}>
            <TopStirfryItemsAugust2021 />
          </Route>
          <Route exact path={Constants.HONEYBAR_ROUTE}>
            <TopHoneybarItemsAugust2021 />
          </Route>
          <Route exact path={Constants.FEATURED_ROUTE}>
            <TopFeaturedItemsAugust2021 />
          </Route>
          <Route path={Constants.TOP_MENU_ROUTE + '*'}>
            <Redirect to={Constants.TOP_MENU_ROUTE} />
          </Route>
          <Route path="/*">
            <Redirect to="/" />
          </Route>
        </Switch>

        {Footer}
      </Router>
    );
  }
}

export default App;
