import React, { useEffect } from 'react';
import {
  scrollToTop,
  trackCurrentPageView,
  trackLink,
  updatePageMetadata
} from '../GlobalFunctions';
import AdsenseAd from '../components/AdsenseAd';

/**
 * A simple page to render the site privacy policy
 * @returns The react component
 */
function PrivacyPolicy(): React.ReactNode {
  useEffect(() => {
    updatePageMetadata('privacy');
    scrollToTop();
    trackCurrentPageView();
  }, []);
  return (
    <div className="content-container container-padding min-content-height">
      <h1 className="mb-20">Privacy Policy</h1>
      <p>
        This web app uses Google Firebase for data storage and user
        authentication. Your personal data is not used anywhere else or given to
        any other parties. Please review the{' '}
        <a
          onClick={() => trackLink('Privacy Policy', 'Cloud Terms')}
          href="https://cloud.google.com/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Cloud terms of service
        </a>{' '}
        for more information.
      </p>
      <AdsenseAd className="mt-80" />
    </div>
  );
}

export default PrivacyPolicy;
