import React from 'react';
//import smoothscroll from 'smoothscroll-polyfill';
import type { FirebaseApp } from 'firebase/app';
import type { User } from 'firebase/auth';
import {
  scrollToTop,
  trackCurrentPageView,
  trackLink,
  updatePageMetadata
} from '../GlobalFunctions';
import AdsenseAd from '../components/AdsenseAd';
import MenuTable from '../components/MenuTable';
import type { MenuCategory, MenuItem } from '../models';

//smoothscroll.polyfill();

/**
 * The component properties
 */
interface NutritionPageProps {
  /** The firebase app */
  readonly firebase: FirebaseApp;
  /** The current menu name */
  readonly menuseason: string;
  /** The currently authenticated user */
  readonly user: User | null;
}

/**
 * The component state
 */
interface NutritionPageState {
  /** All menu data */
  data: MenuItem[];
  /** The create your own salad menu items */
  cyoSalad: MenuItem[];
  /** The create your own stirfry menu items */
  cyoStirfry: MenuItem[];
  /** The create your own honeybar menu items */
  honeybar: MenuItem[];
  /** The featured menu items */
  featured: MenuItem[];
  /** The currently selected menu category id */
  currentCategory: MenuCategory | null;
  /** The currently selected menu category as a pretty string */
  currentCategoryTitle: string;
  /** Whether the menu has juice listed */
  hasJuice: boolean;
  /** Tracks how far to scroll */
  headingTop: number;
  /** Whether to show the calculator on the page */
  showCalculator: boolean;
  /** The source url of the menu if available */
  source?: string | null;
}

class NutritionPage extends React.Component<
  NutritionPageProps,
  NutritionPageState
> {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cyoSalad: [],
      cyoStirfry: [],
      honeybar: [],
      featured: [],
      currentCategory: null,
      currentCategoryTitle: '',
      hasJuice: false,
      headingTop: 0,
      showCalculator: false,
      source: null
    };
    this.changeData = this.changeData.bind(this);
  }
  componentDidMount() {
    // Set the scroll to position (heading div)
    this.setState({
      headingTop:
        document.getElementById('heading')?.getBoundingClientRect().top ?? 0
    });
    this.fetchMenuData(this.props.menuseason);
    updatePageMetadata('home');
    scrollToTop();
    trackCurrentPageView();
  }

  // make sure to remove the listeners when unmounted
  /* componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
    document.removeEventListener("keydown", this.keyPressHandler, false);
  } */
  componentDidUpdate(prevProps) {
    if (prevProps.menuseason !== this.props.menuseason) {
      this.fetchMenuData(this.props.menuseason);
    }
  }
  changeData(datasrc: MenuCategory) {
    // Reset the total
    //resettotals();
    // Change data state to category's data
    if (datasrc) {
      let catTitle = '';

      // Change data state to category's data
      if (datasrc === 'featured') {
        catTitle = 'Featured Nutrition';
      } else if (datasrc === 'cyoSalad') {
        catTitle = 'CYO Salad Nutrition';
      } else if (datasrc === 'cyoStirfry') {
        catTitle = 'CYO Stir Fry Nutrition';
      } else if (datasrc === 'honeybar') {
        catTitle =
          'CYO Honeybar ' +
          (this.state.hasJuice ? 'and Juices ' : '') +
          'Nutrition'; // Juices only available in some menus
      }
      this.setState({
        data: this.state[datasrc],
        currentCategory: datasrc,
        currentCategoryTitle: catTitle
      });

      trackLink('Category Nav', datasrc);
    }

    // Only scroll down to menu if calculator is shown (aka not on page load)
    if (this.state.showCalculator) {
      // Scroll to menu listing
      const scrollto = this.state.headingTop - 60;
      setTimeout(function () {
        window.scrollTo({
          top: scrollto,
          behavior: 'smooth'
        });
      });
    }

    // Set show calculator state so that class will change on category button container
    this.setState({
      showCalculator: true
    });
  }
  fetchMenuData(season?: string) {
    if (season) {
      const storedData = JSON.parse(localStorage.getItem(season));
      const currentCat = this.state.currentCategory;
      // Used data from local storage instead of fetching if possible
      if (storedData != null) {
        this.setState(storedData, () => {
          this.changeData(currentCat);
        });
      } else {
        fetch('/files/' + season + '.json', {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        })
          .then(async (response) => {
            return response.json();
          })
          .then((json) => {
            let src = json.source ? json.source : null;
            const data: Record<MenuCategory, MenuItem[]> = {
              cyoSalad: [],
              cyoStirfry: [],
              honeybar: [],
              featured: []
            };
            // Sort data into respective categories
            for (var i = 0; i < json.menuitems.length; i++) {
              data[json.menuitems[i].category].push(json.menuitems[i]);
            }
            let hasJuice =
              data.honeybar[data.honeybar.length - 1].categorytitle === 'Juice'; // Check last honeybar item to see if juices are on this menu
            this.setState({
              ...data,
              source: src,
              hasJuice: hasJuice
            });

            const cachedValue = {
              ...data,
              source: src,
              data: [],
              hasJuice: hasJuice
            };
            localStorage.setItem(season, JSON.stringify(cachedValue)); // Store organized data in local storage for faster loading in the future
            this.changeData(this.state.currentCategory);
          });
      }
    }
  }
  render() {
    return (
      <div className="home">
        <AdsenseAd />
        <div className="instructions top-content content-container container-padding">
          {this.props.user != null && this.props.user.displayName ? (
            <p>Hi, {this.props.user.displayName}!</p>
          ) : (
            ''
          )}
          <p>
            Honeygrow is a healthy restaurant, but it’s helpful to know what
            nutritional value you can expect from all of their items. So whether
            you’re on a strict meal plan or you just want to make healthier
            eating choices, this calculator is the easiest way to figure out the
            nutritional content of all items currently available on their menu.
          </p>
          <p>
            Although this website isn’t officially affiliated with the Honeygrow
            business, it uses the official nutritional data from their menu.
            Start your meal calculations by selecting a menu category below!
          </p>
        </div>
        <div
          className={
            this.state.showCalculator
              ? 'menu-options remove-margin open'
              : 'menu-options remove-margin'
          }
        >
          <button
            title="Featured Salads"
            id="featured"
            className={
              this.state.currentCategory === 'featured' ? 'current-cat' : ''
            }
            onClick={() => this.changeData('featured')}
          >
            <div
              className="menu-category"
              style={{ backgroundImage: 'url(\'/images/featured.jpg\')' }}
            >
              <h2>Featured</h2>
            </div>
          </button>
          <button
            title="Create Your Own Salad"
            id="cyoSalad"
            className={
              this.state.currentCategory === 'cyoSalad' ? 'current-cat' : ''
            }
            onClick={() => this.changeData('cyoSalad')}
          >
            <div
              className="menu-category"
              style={{ backgroundImage: 'url(\'/images/salad.jpg\')' }}
            >
              <h2>CYO Salad</h2>
            </div>
          </button>
          <button
            title="Create Your Own Stir Fry"
            id="cyoStirfry"
            className={
              this.state.currentCategory === 'cyoStirfry' ? 'current-cat' : ''
            }
            onClick={() => this.changeData('cyoStirfry')}
          >
            <div
              className="menu-category"
              style={{ backgroundImage: 'url(\'/images/noodles.jpg\')' }}
            >
              <h2>CYO Stir&nbsp;Fry</h2>
            </div>
          </button>
          <button
            title="Create Your Own Honeybar"
            id="honeybar"
            className={
              this.state.currentCategory === 'honeybar' ? 'current-cat' : ''
            }
            onClick={() => this.changeData('honeybar')}
          >
            <div
              className="menu-category"
              style={{ backgroundImage: 'url(\'/images/yogurt.jpg\')' }}
            >
              <h2>CYO Honeybar {this.state.hasJuice ? 'and Juices' : ''}</h2>
            </div>
          </button>
        </div>
        <h1 id="heading">{this.state.currentCategoryTitle}</h1>
        <MenuTable
          user={this.props.user}
          data={this.state.data}
          firebase={this.props.firebase}
          menuseason={this.props.menuseason}
        />
        {this.state.source && (
          <div className="source">
            Data source:{' '}
            <a
              href={this.state.source}
              rel="noopener noreferrer"
              target="_blank"
            >
              Honeygrow {this.props.menuseason.replace('-', ' ')} menu (PDF)
            </a>
          </div>
        )}
        <AdsenseAd className="mb-20" />
      </div>
    );
  }
}

export default NutritionPage;
