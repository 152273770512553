import React, { useEffect } from 'react';

function AdsenseAd(props) {
  useEffect(() => {
    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  }, []);

  const className = props.className ? props.className : '';
  return (
    <div
      className={'mt-30 mb-20 content-container container-padding ' + className}
    >
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-format="fluid"
        data-ad-layout-key="-gw-3+1f-3d+2z"
        data-ad-client="ca-pub-7607325002121806"
        data-ad-slot="7619370803"
      ></ins>
    </div>
  );
}

export default AdsenseAd;
