import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { User } from 'firebase/auth';

const emailRegEx = /\S+@\S+\.\S\S+/;

interface CallbackProps {
  email: string;
  emailError: boolean;
}

/**
 * The component properties
 */
interface EmailInputProps {
  /** Optional callback function to call on input value change */
  readonly callback?: (value?: CallbackProps) => void;
  /** The id attribute for the input element */
  readonly id: string;
  /** The id of the next element to focus on */
  readonly nextFocus: string;
  /** The currently authenticated user, used for prepopulating the email value */
  readonly user?: User | null;
  /** Whether to enable validation */
  readonly validation?: boolean;
}

/**
 * The component state
 */
interface EmailInputState {
  /** Whether the email value is visible */
  isPeek: boolean;
  /** The current input value */
  email: string;
  /** Whether there's an error with the current input */
  emailError: boolean | null;
}
class EmailInput extends React.Component<EmailInputProps, EmailInputState> {
  constructor(props) {
    super(props);
    this.state = {
      email:
        this.props.user?.uid && this.props.user?.email
          ? this.props.user.email
          : '',
      emailError:
        this.props.user != null && this.props.user.uid != null ? false : null,
      isPeek: false
    };
    this.appendEmailDomain = this.appendEmailDomain.bind(this);
    this.handleDomainKeyDown = this.handleDomainKeyDown.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== null && this.props.user === null) {
      this.handleEmailChange({ target: { value: '' } });
    }
  }
  appendEmailDomain(e) {
    let email = this.state.email;
    let atIndex = email.indexOf('@');
    if (atIndex > -1) {
      email = email.substring(0, atIndex);
    }
    let newEmail = email + e.target.innerText;
    this.handleEmailChange({ target: { value: newEmail } });
    setTimeout(() => document.getElementById(this.props.nextFocus)?.focus(), 0);
  }
  handleDomainKeyDown(e) {
    if (e.key === 'Enter') {
      this.appendEmailDomain(e);
    }
  }
  handleEmailChange(e) {
    let value = e.target.value; // New email
    let isCurrentError = this.state.emailError; // Current email
    let isNewValid = this.isValidEmail(value);
    this.setState({ email: value });
    if (isNewValid || (isCurrentError === false && !isNewValid)) {
      // Update error when email is now valid or now invalid
      this.setState({ emailError: !isNewValid });
    }
    if (this.props.callback) {
      this.props.callback({ email: value, emailError: !isNewValid });
    }
  }
  isValidEmail(email) {
    return email.match(emailRegEx) !== null;
  }
  validateEmail(e) {
    this.setState(
      {
        emailError: !this.isValidEmail(this.state.email)
      },
      () => {
        if (this.props.callback) {
          this.props.callback();
        }
      }
    );
  }
  render() {
    let areDomainBtnsDisabled =
      this.state.email.length === 0 ? true : undefined;
    return (
      <div>
        <label id={this.props.id + '-label'} htmlFor={this.props.id}>
          Email
        </label>
        {this.props.validation !== false && this.state.emailError !== null && (
          <span
            id={this.props.id + '-status'}
            className="red-text ml-10 input-validation"
          >
            {this.state.emailError ? (
              'Please enter a valid email address.'
            ) : (
              <FontAwesomeIcon className="green-text" icon={faCheck} />
            )}
          </span>
        )}
        <input
          id={this.props.id}
          name="email"
          type="email"
          required
          onChange={this.handleEmailChange}
          onBlur={this.validateEmail}
          autoComplete="email"
          value={this.state.email}
        />
        <div className="email-buttons">
          <button
            type="button"
            className="mr-5"
            disabled={areDomainBtnsDisabled}
            onMouseDown={this.appendEmailDomain}
            onKeyDown={this.handleDomainKeyDown}
          >
            @gmail.com
          </button>
          <button
            type="button"
            className="mr-5"
            disabled={areDomainBtnsDisabled}
            onMouseDown={this.appendEmailDomain}
            onKeyDown={this.handleDomainKeyDown}
          >
            @yahoo.com
          </button>
          <button
            type="button"
            className="mr-5"
            disabled={areDomainBtnsDisabled}
            onMouseDown={this.appendEmailDomain}
            onKeyDown={this.handleDomainKeyDown}
          >
            @icloud.com
          </button>
          <button
            type="button"
            disabled={areDomainBtnsDisabled}
            onMouseDown={this.appendEmailDomain}
            onKeyDown={this.handleDomainKeyDown}
          >
            @hotmail.com
          </button>
        </div>
      </div>
    );
  }
}

export default EmailInput;
