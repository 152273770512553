import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import {
  scrollToTop,
  trackEvent,
  trackLink,
  trackError,
  updatePageMetadata,
  trackCurrentPageView
} from '../GlobalFunctions';
import EmailInput from '../components/form/EmailInput';
import TextInput from '../components/form/TextInput';
import AdsenseAd from '../components/AdsenseAd';
import { User } from 'firebase/auth';

const minMessageLength = 25;

/**
 * The component properties
 */
interface ContactMeProps {
  /** The currently authenticated user */
  readonly user: User | null;
}

/**
 * The component state
 */
interface ContactMeState {
  /** The user's name */
  name?: string | null;
  /** The user's email address */
  email: string | null;
  /** Whether there's an email error */
  emailError: boolean | null;
  /** The user's message */
  message: string;
  /** Whether there's an error in the user's message */
  messageError: boolean | null;
  /** Whether the form was submitted */
  formSubmitted: boolean;
  /** The status code of the form submission */
  status: number;
}

class ContactMe extends React.Component<ContactMeProps, ContactMeState> {
  constructor(props) {
    super(props);
    this.state = {
      name:
        this.props.user != null && this.props.user.uid != null
          ? this.props.user.displayName
          : null,
      email:
        this.props.user != null && this.props.user.uid != null
          ? this.props.user.email
          : '',
      emailError:
        this.props.user != null && this.props.user.uid != null ? false : null,
      message: '',
      messageError: null,
      formSubmitted: false,
      status: 0
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }
  componentDidMount() {
    updatePageMetadata('contact');
    scrollToTop();
    trackCurrentPageView();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.user !== null && this.props.user == null) {
      this.handleNameChange({ text: '' });
      this.handleEmailChange({ email: '', emailError: null });
    }
  }
  handleNameChange(textInput) {
    this.setState({ name: textInput.text });
  }
  handleEmailChange(emailInput) {
    // emailInput is a json object: { email: STRING, emailError: BOOLEAN}
    this.setState(emailInput);
  }
  handleMessageChange(e) {
    this.setState({
      message: e.target.value,
      messageError: e.target.value.length < minMessageLength
    });
  }
  handleFormSubmit(e) {
    e.preventDefault();
    document
      .getElementById('contact-me-email')
      ?.setAttribute('disabled', 'true');
    var formData = new FormData();
    formData.append('name', this.state.name ?? '');
    formData.append('email', this.state.email ?? '');
    formData.append('message', this.state.message ?? '');

    fetch('/files/contact.php', {
      method: 'POST',
      body: formData
    })
      .then((res) => {
        this.setState({
          formSubmitted: true,
          status: res.status
        });
        trackEvent('Contact Form Submitted', 'Success');
      })
      .catch((error) => {
        this.setState({
          formSubmitted: true
        });
        trackError('Contact Form Submitted', 'Failure - ' + error.message);
      });
  }
  render() {
    let isValidName = this.state.name && this.state.name.length;
    let fieldsAreComplete =
      this.state.emailError === false &&
      isValidName &&
      this.state.messageError === false;
    let formIsSuccess = 200 <= this.state.status && this.state.status < 300;
    return (
      <div className="contact content-container container-padding">
        <h1 className="mb-20">Contact</h1>
        <p>
          If you have a question/comment about this nutritional calculator or
          would like to get in touch with the developer of this site, fill out
          the form below! If you want more information about nutrition at
          Honeygrow, reach out to them directly on the official{' '}
          <a
            onClick={() => trackLink('Contact Me', 'Honeygrow website link')}
            href="https://www.honeygrow.com/contact/"
          >
            Honeygrow website.
          </a>
        </p>
        {!this.state.formSubmitted && (
          <form id="contact-me-form" onSubmit={this.handleFormSubmit}>
            <TextInput
              callback={this.handleNameChange}
              id="contact-me-name"
              name="name"
              autocomplete="name"
              label="Name"
              default={this.state.name ?? undefined}
            />

            <EmailInput
              callback={this.handleEmailChange}
              user={this.props.user}
              id="contact-me-email"
              nextFocus="contact-me-message"
            />

            <label htmlFor="contact-me-message">Message</label>
            {this.state.messageError !== null && (
              <span id="contact-me-message-status" className="red-text ml-10">
                {this.state.messageError ? (
                  minMessageLength -
                  this.state.message.length +
                  ' characters to go!'
                ) : (
                  <FontAwesomeIcon className="green-text" icon={faCheck} />
                )}
              </span>
            )}
            <textarea
              id="contact-me-message"
              name="message"
              required
              onChange={this.handleMessageChange}
              minLength={minMessageLength}
            />

            <button
              id="contact-me-submit"
              className="btn btn-alt mt-20"
              name="submit"
              type="submit"
              disabled={fieldsAreComplete ? undefined : true}
            >
              Send
            </button>
          </form>
        )}
        {this.state.formSubmitted && formIsSuccess && (
          <div className="form-status">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="mb-10 blue-text icon"
            />
            Submitted! Thank you for your feedback.
          </div>
        )}
        {this.state.formSubmitted && !formIsSuccess && (
          <div className="form-status">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mb-10 red-text icon"
            />
            Oops, there was an error. Please refresh the page and try again
            soon.
          </div>
        )}
        <AdsenseAd className="mt-80" />
      </div>
    );
  }
}

export default ContactMe;
