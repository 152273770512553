import { FirebaseOptions } from 'firebase/app';

export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyD6XLEYVKFOoGhFABOp58ckcO6A2GLTa7w',
  appId: '1:896103448936:web:fe8511f5b969d104fad6dd',
  authDomain: 'hg-nutrition.firebaseapp.com',
  databaseURL: 'https://hg-nutrition.firebaseio.com',
  messagingSenderId: '896103448936',
  projectId: 'hg-nutrition',
  storageBucket: 'hg-nutrition.appspot.com'
};
