import {
  faCalculator,
  faCarrot,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  scrollToTop,
  trackCurrentPageView,
  trackLink,
  updatePageMetadata
} from '../GlobalFunctions';
import AdsenseAd from '../components/AdsenseAd';

function AboutPage() {
  useEffect(() => {
    updatePageMetadata('about');
    scrollToTop();
    trackCurrentPageView();
  }, []);
  return (
    <div className="content-container container-padding min-content-height">
      <h1 className="mb-20">About</h1>
      <h2 className="mb-20">How to Use This Nutritional Calculator</h2>
      <p>
        Although this website isn't officially affiliated with the Honeygrow
        business, it uses the official nutritional data from their menu. Here's
        how you use the calculator:
      </p>
      <div className="box-container">
        <div className="box">
          <div className="icon">
            <FontAwesomeIcon icon={faCarrot} />
          </div>
          <p>
            First, choose whether you're interested in Honeygrow's salads, stir
            fry, or Honeybar selections. You can also browse their Featured
            section for a selection of Honeygrow's currated meals.
          </p>
          <p>
            The names of each menu item are on the leftmost side of the chart,
            click whichever items you would like to add to your meal.
          </p>
        </div>
        <div className="box">
          <div className="icon">
            <FontAwesomeIcon icon={faChartBar} />
          </div>
          <p>
            Menu items are marked with colored emblems that represent specific
            nutritional content:
          </p>
          <ul>
            <li>M: Contains milk</li>
            <li>E: Contains eggs</li>
            <li>W: Contains wheat</li>
            <li>T: Contains tree nuts</li>
            <li>S: Contains soy</li>
            <li>SF: Contains shellfish</li>
          </ul>
          <p>
            If you have specific dietary restrictions, these emblems will help
            you identify and avoid foods that contain certain ingredients. Aside
            from that, macronutrient data can be found in the columns to the
            right of each menu item.
          </p>
        </div>
        <div className="box">
          <div className="icon">
            <FontAwesomeIcon icon={faCalculator} />
          </div>
          <p>
            Once you've made a decision, press the grey plus symbol next to your
            selection and it will appear at the bottom of your browser. This is
            a running total that will include every menu item you add, which
            will give you the total nutritional value of your meal.
          </p>
          <p>
            You can remove anything that you've added by pressing the red X
            button.
          </p>
        </div>
      </div>
      <p className="mt-20">
        If you{' '}
        <Link
          onClick={() => trackLink('About', 'Create an Account')}
          to="/login/"
        >
          create an account
        </Link>{' '}
        on our site, you can save your meal information for future reference.
        This information is securely stored and will never be shared with
        outside parties or used for promotional purposes; it's just a convenient
        feature that's intended to help you plan healthy meals at a great
        restaurant!
      </p>
      <h2>Notice</h2>
      <p>
        Remember— this website is not officially affiliated with Honeygrow, so
        you'll have to{' '}
        <a
          rel="noopener noreferrer"
          className="external"
          onClick={() => trackLink('About', 'Visit their Website')}
          href="https://www.honeygrow.com/"
          target="_blank"
        >
          visit their website
        </a>{' '}
        to actually place your order or request more information about
        nutrition. This is just a nutrition calculator!
      </p>
      <AdsenseAd />
    </div>
  );
}

export default AboutPage;
