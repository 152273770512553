import React, { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { updatePageMetadata } from '../GlobalFunctions';
import * as Constants from '../Constants';
import AdsenseAd from './AdsenseAd';

const BACK_LINK = (
  <Link className="back-link" to={Constants.TOP_MENU_ROUTE}>
    Back
  </Link>
);

interface BlogPostProps {
  /** The blog content */
  children: ReactNode;
  /** TODO: Confirm what this is */
  meta?: any;
  /** The blog post timeframe @example 'April 2024' */
  timeframe?: string;
  /** The blog post title */
  title: string;
}

function BlogPost(props: BlogPostProps) {
  useEffect(() => {
    if (props.meta) {
      updatePageMetadata('top-menu');
    }
  });
  const currentRoute = window.location.pathname;
  return (
    <div className="content-container container-padding min-content-height">
      <AdsenseAd className="mb-40" />
      {BACK_LINK}
      <h1 className="mb-20">{props.title}</h1>
      {props.timeframe && <p className="italics smaller">{props.timeframe}</p>}
      {props.children}
      <ul className="no-marker mt-40 center">
        {currentRoute !== Constants.SALAD_ROUTE && (
          <li>
            <Link to={Constants.SALAD_ROUTE}>Top Salad Menu Items</Link>
          </li>
        )}
        {currentRoute !== Constants.STIRFRY_ROUTE && (
          <li>
            <Link to={Constants.STIRFRY_ROUTE}>Top Stirfry Menu Items</Link>
          </li>
        )}
        {currentRoute !== Constants.HONEYBAR_ROUTE && (
          <li>
            <Link to={Constants.HONEYBAR_ROUTE}>Top Honeybar Menu Items</Link>
          </li>
        )}
        {currentRoute !== Constants.FEATURED_ROUTE && (
          <li>
            <Link to={Constants.FEATURED_ROUTE}>Top Featured Menu Items</Link>
          </li>
        )}
      </ul>
      <AdsenseAd className="mt-80" />
    </div>
  );
}

export default BlogPost;
