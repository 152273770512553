import React from 'react';
import { scrollToTop, trackLink } from '../GlobalFunctions';
import { MenuItem } from '../models';

/**
 * The component properties
 */
interface MenuBreakItemProps {
  /** The current menu item */
  readonly item: MenuItem;
  /** Whether the current viewport is mobile */
  readonly mobile: boolean;
}

class MenuBreakItem extends React.Component<MenuBreakItemProps> {
  constructor(props) {
    super(props);
    this.scrollToTopMenuBreak = this.scrollToTopMenuBreak.bind(this);
  }

  // Scroll to top of page
  scrollToTopMenuBreak() {
    scrollToTop();

    // Submit click event to Google Analytics
    trackLink('Scroll to top', 'Mobile - ' + this.props.item.categorytitle);
  }
  render() {
    return (
      <tr className="menu-break">
        <td colSpan={this.props.mobile ? 2 : 14}>
          <div>{this.props.item.categorytitle}</div>
          {this.props.mobile && (
            <div className="back-to-top">
              <button
                type="button"
                onClick={this.scrollToTopMenuBreak}
                title="Back to Top"
                aria-label="Scroll back to top of screen"
              >
                <i className="fas fa-arrow-up"></i>
              </button>
            </div>
          )}
        </td>
      </tr>
    );
  }
}

export default MenuBreakItem;
