import 'babel-polyfill';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

if (rootElement!.hasChildNodes()) {
  hydrateRoot(rootElement!, <App />);
} else {
  const root = createRoot(rootElement!);
  root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
