import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Firestore,
  addDoc,
  collection,
  getFirestore
} from 'firebase/firestore';
import React from 'react';
import { Link } from 'react-router-dom';
import { trackError, trackLink } from '../GlobalFunctions';
import { FirebaseApp } from 'firebase/app';
import { User } from 'firebase/auth';
import { Meal } from '../models/meal';
import { MenuCategory } from '../models';

/**
 * The component properties
 */
interface SaveMealProps {
  /** The firebase app */
  readonly firebase: FirebaseApp;
  /** The current menu @example 'spring-2024' */
  readonly menu: string;
  /** Method to toggle the visibility of the save modal */
  readonly toggleModal: () => void;
  /** The currently authenticated user */
  readonly user: User | null;
}

/**
 * The component state
 */
interface SaveMealState {
  /** User-given name of the meal */
  mealName: string;
  /** Whether the save meal form was submitted */
  formSubmitted: boolean;
}

class SaveMeal extends React.Component<SaveMealProps, SaveMealState> {
  readonly db: Firestore;

  constructor(props) {
    super(props);
    this.state = {
      mealName: '',
      formSubmitted: false
    };
    this.handleForm = this.handleForm.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.db = getFirestore(this.props.firebase);
  }
  handleForm(e) {
    e.preventDefault();
    let today = new Date();
    let todayString = today.toString();
    const category = document.querySelector('.current-cat')?.id;
    if (!category) {
      console.error('No category specified');
    } else if (!this.props.user?.uid) {
      console.error('Invalid user id', this.props.user?.uid);
    } else {
      let data: Meal = {
        category: category as MenuCategory,
        created: todayString,
        items: [],
        lastModified: todayString,
        menu: this.props.menu,
        private: true,
        title: this.state.mealName,
        user: this.props.user?.uid
      };
      document.querySelectorAll('.selected').forEach((item) => {
        data.items.push(item.id);
      });
      let cmp = this;
      addDoc(collection(this.db, 'meals'), data)
        .then(function () {
          cmp.setState({
            formSubmitted: true
          });
        })
        .catch(function (error) {
          console.error('Error submitting to firebase: ', error);
          trackError('Update Meal', 'Error in SaveMeal.js by handleForm');
        });
    }
  }
  handleInput(e) {
    this.setState({ mealName: e.target.value });
  }
  render() {
    return (
      <div className="modal-bg">
        <div className="modal-container">
          <button className="btn-close" onClick={this.props.toggleModal}>
            <FontAwesomeIcon icon={faTimes} className="close-icon" />
            <span className="off-screen">Close modal</span>
          </button>
          <h3>Save Your Meal</h3>
          {this.props.user && !this.state.formSubmitted && (
            <form id="save-meal-form" onSubmit={this.handleForm}>
              <label htmlFor="meal-name">Name: </label>
              <input
                type="text"
                onChange={this.handleInput}
                value={this.state.mealName}
              />
              <button
                className="btn btn-alt"
                type="submit"
                disabled={this.state.mealName === ''}
              >
                Save
              </button>
            </form>
          )}
          {this.props.user && this.state.formSubmitted && (
            <div>
              <p>Meal Saved!</p>
              <div>
                <button
                  onClick={this.props.toggleModal}
                  className="btn btn-alt"
                  type="button"
                >
                  Back
                </button>
                <Link
                  onClick={() => trackLink('Saved Meal', 'View Meals')}
                  className="ml-10 btn btn-alt"
                  type="button"
                  to="/account/"
                >
                  View Meals
                </Link>
              </div>
            </div>
          )}
          {!this.props.user && (
            <div>
              <p>Please log in to use this functionality.</p>
              <div>
                <Link
                  onClick={() => trackLink('Save Meal', 'Log In')}
                  className="btn btn-alt"
                  type="button"
                  to="/login/"
                >
                  Log In
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SaveMeal;
