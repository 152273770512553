import React from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../Constants';
import { trackLink } from '../GlobalFunctions';

export const Footer = (
  <footer>
    <div className="content-container">
      <div className="disclaimer">
        <p>
          Current site data is from the{' '}
          <a
            rel="noopener noreferrer"
            className="external"
            onClick={() =>
              trackLink('Footer', 'Honeygrow April 2024 menu link')
            }
            href="https://www.honeygrow.com/menu/"
            target="_blank"
          >
            Honeygrow website
          </a>
          . Calorie counts are based on “light” portions of dressings + sauces.
          2,000 calories a day is used for general nutrition advice, but calorie
          needs may vary. Additional nutrition information available upon
          request at the restaurant. If you have a severe food allergy or
          sensitivity, please notify an ambassador or manager in the restaurant.
          Although some items do not contain any particular allergens, this can
          not be guaranteed. All stir-fries are made with a small amount of
          non-GMO garlic oil.{' '}
        </p>
        <p>
          This site is not affiliated, associated, authorized, endorsed by, or
          in any way officially connected with Honeygrow. The official Honeygrow
          website can be found at{' '}
          <a
            className="external"
            rel="noopener noreferrer"
            onClick={() => trackLink('Footer', 'honeygrow.com')}
            href="https://www.honeygrow.com/"
            target="_blank"
          >
            honeygrow.com
          </a>
          . Just a nerdy web developer working on her React skills.
        </p>
        <ul className="footer-links">
          <li>
            <Link onClick={() => trackLink('Footer', 'Login')} to="/login/">
              Log In
            </Link>
          </li>
          <li>
            <Link onClick={() => trackLink('Footer', 'About')} to="/about/">
              About
            </Link>
          </li>
          <li>
            <Link
              onClick={() => trackLink('Footer', 'Top Menu')}
              to={Constants.TOP_MENU_ROUTE}
            >
              Top Menu
            </Link>
          </li>
          <li>
            <Link onClick={() => trackLink('Footer', 'Contact')} to="/contact/">
              Contact
            </Link>
          </li>
          <li>
            <Link onClick={() => trackLink('Footer', 'Privacy')} to="/privacy/">
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);
