import { faCheck, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FirebaseApp } from 'firebase/app';
import { User } from 'firebase/auth';
import {
  DocumentData,
  Firestore,
  doc,
  getDoc,
  getFirestore,
  updateDoc
} from 'firebase/firestore';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  errorMessageBanner,
  messageBanner,
  scrollToTop,
  trackCurrentPageView,
  trackError,
  trackLink,
  updateMetadata,
  updatePageMetadata
} from '../GlobalFunctions';
import MenuTable from '../components/MenuTable';
import { Unauthorized } from '../components/Unauthorized';
import { MenuItem } from '../models';
import { Meal, MealNutrition } from '../models/meal';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

interface Params {
  /** Meal id from the route params */
  readonly mealId: string;
}

/**
 * The component properties
 */
interface MealPageProps {
  /** The firebase app */
  readonly firebase: FirebaseApp;
  /** The current menu name */
  readonly menuseason: string;
  /** The route params */
  readonly params: Params;
  /** The currently authenticated user */
  readonly user: User | null;
}

/**
 * The component state
 */
interface MealPageState {
  /** Whether the authenticated user owns the current meal */
  cmpHasUser: boolean;
  /** The list of menu items */
  menu: MenuItem[];
  /** Whether the user is on a mobile device */
  isMobile: boolean;
  /** The total tracked nutritional info for the current selected menu items */
  totals: MealNutrition;
  resetState: boolean;
  meal: Meal | null;
  firstLoad: boolean;
  invalidDoc: boolean;
  isCorrectUser: boolean;
  editNameActive: boolean;
  newMealName: string;
}

class MealPage extends React.Component<MealPageProps, MealPageState> {
  readonly db: Firestore;

  constructor(props) {
    super(props);
    this.state = {
      cmpHasUser: false,
      menu: [],
      isMobile: window.innerWidth < 992,
      totals: {
        totalCal: 0,
        totalFatCal: 0,
        totalFat: 0,
        totalSatFat: 0,
        totalTransFat: 0,
        totalChol: 0,
        totalSodium: 0,
        totalCarbs: 0,
        totalFiber: 0,
        totalSugar: 0,
        totalProtein: 0
      },
      resetState: false,
      meal: null,
      firstLoad: true,
      invalidDoc: false,
      isCorrectUser: true,
      editNameActive: false,
      newMealName: ''
    };
    this.getData = this.getData.bind(this);
    this.setUpTable = this.setUpTable.bind(this);
    this.handleMealNameSubmit = this.handleMealNameSubmit.bind(this);
    this.handleMealNameChange = this.handleMealNameChange.bind(this);
    this.toggleNameEdit = this.toggleNameEdit.bind(this);
    this.db = getFirestore(this.props.firebase);
  }
  componentDidMount() {
    //window.addEventListener('resize', this.handleWindowSizeChange);
    if (this.props.user !== null) {
      this.getData();
    } else {
      console.error('No user authenticated.');
      updatePageMetadata('unauthorized');
      trackError('Meal Page', 'No user authenticated');
    }
    scrollToTop();
    trackCurrentPageView();
  }
  getData() {
    const { mealId } = this.props.params;
    const cmp = this;
    const userId = this.props.user?.uid ?? null;
    if (mealId && userId) {
      getDoc(doc(this.db, 'meals', mealId))
        .then(function (doc) {
          if (doc.exists()) {
            let data = doc.data();
            data.id = mealId;
            if (data.user === userId) {
              cmp.setState(
                {
                  cmpHasUser: true,
                  meal: data as Meal,
                  newMealName: data.title
                },
                () => {
                  cmp.setUpTable();
                  updateMetadata(
                    data.title,
                    'View the nutritional details for ' + data.title + '.',
                    'https://www.hgnutrition.com/images/ogimage.jpg',
                    'noindex'
                  );
                }
              );
            } else {
              console.error('Error: Invalid User');
              updatePageMetadata('unauthorized');
              trackError('Meal Page', 'Invalid user');
              cmp.setState({
                cmpHasUser: false,
                firstLoad: false,
                isCorrectUser: false
              });
            }
          } else {
            console.error('Error. No such document: ' + mealId);
            trackError('Meal Page', 'Meal doesn\'t exist: ' + mealId);
            cmp.setState({
              invalidDoc: true,
              firstLoad: false
            });
          }
        })
        .catch(function (error) {
          console.error('Error getting document:', error);
          trackError('Meal Page', 'Unknown error: ' + JSON.stringify(error));
          cmp.setState({
            invalidDoc: true,
            firstLoad: false
          });
        });
    } else {
      trackError(
        'Meal Page',
        `Invalid user (${userId}) or meal id (${mealId})`
      );
    }
  }
  setUpTable() {
    if (this.state.meal) {
      const season = this.state.meal.menu;
      if (season) {
        const storedData = JSON.parse(localStorage.getItem(season));
        const currentCat = this.state.meal.category;
        // Used data from local storage instead of fetching if possible
        if (storedData?.[currentCat]) {
          this.setState({
            menu: storedData[currentCat]
          });
        } else {
          fetch('/files/' + season + '.json')
            .then((response) => response.json())
            .then((json) => {
              // Sort data into respective categories
              const menuItems: MenuItem[] = [];
              for (var i = 0; i < json.menuitems.length; i++) {
                if (json.menuitems[i].category === currentCat)
                  menuItems.push(json.menuitems[i]);
              }
              this.setState({ menu: menuItems });
            })
            .then(() => {
              this.setState({
                firstLoad: false
              });
            });
        }
      }
    }
  }
  handleMealNameChange(e) {
    this.setState({
      newMealName: e.target.value
    });
  }
  handleMealNameSubmit(e) {
    e.preventDefault();
    let item = this.state.meal;
    if (item) {
      item.title = this.state.newMealName;
      this.setState(
        {
          meal: item,
          editNameActive: false
        },
        () => {
          // Save Meal
          let today = new Date();
          let todayString = today.toString();
          item.lastModified = todayString;
          if (item.id) {
            updateDoc(doc(this.db, 'meals', item.id), item as DocumentData)
              .then(function () {
                messageBanner('Saved!', 3000, false);
                updateMetadata(
                  item.title,
                  'View the nutritional details for ' + item.title + '.',
                  '/images/ogimage.jpg',
                  'noindex'
                );
              })
              .catch(function (error) {
                console.error('Error submitting to firebase');
                trackError(
                  'Meal Page',
                  'Couldn\'t save meal: ' + JSON.stringify(error)
                );
                errorMessageBanner(error);
              });
          }
        }
      );
    }
  }
  toggleNameEdit() {
    this.setState({
      editNameActive: !this.state.editNameActive,
      newMealName: this.state.meal?.title ?? ''
    });
  }
  render() {
    const authorized = this.props.user !== null && this.state.isCorrectUser;
    return (
      <div className="content-container container-padding">
        {(!this.props.user || !authorized) &&
          !this.state.invalidDoc &&
          Unauthorized}
        {!this.state.firstLoad && this.state.invalidDoc && (
          <div>
            <h1 id="heading" className="mb-10">
              Meal Could Not Be Found
            </h1>
            <p>
              This meal does not exist. Please try going back to your account
              page to see your current meal list.
            </p>
            <Link
              onClick={() =>
                trackLink('Meal Page', 'Meal not found - Account Btn')
              }
              className="btn btn-alt"
              to="/account/"
            >
              Account
            </Link>
            <Link
              onClick={() =>
                trackLink('Meal Page', 'Meal not found - Home Btn')
              }
              className="btn btn-alt ml-10"
              to="/"
            >
              Back to Home
            </Link>
          </div>
        )}
        {authorized && this.state.meal && (
          <div>
            {!this.state.editNameActive && (
              <h1 id="heading">
                {this.state.meal.title}{' '}
                <button
                  id="edit-meal-name"
                  title="Edit meal name"
                  type="button"
                  className="title-icon"
                  onClick={this.toggleNameEdit}
                >
                  <FontAwesomeIcon icon={faPen} />
                  <span className="off-screen">Edit meal name</span>
                </button>
              </h1>
            )}
            {this.state.editNameActive && (
              <form
                className="flex center name-edit"
                onSubmit={this.handleMealNameSubmit}
              >
                <input
                  type="text"
                  value={this.state.newMealName}
                  onChange={this.handleMealNameChange}
                />
                <div className="vertical-center mt-10 mb-10 ml-5">
                  <button
                    title="Save new meal name"
                    type="submit"
                    className="btn-small btn-alt"
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    <span className="off-screen">Save new meal name</span>
                  </button>
                  <button
                    title="Cancel meal name edit"
                    type="button"
                    className="btn-small btn-primary mt-2"
                    onClick={this.toggleNameEdit}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                    <span className="off-screen">Cancel meal name edit</span>
                  </button>
                </div>
              </form>
            )}
            {this.state.menu.length > 0 && (
              <MenuTable
                user={this.props.user}
                data={this.state.menu}
                preselectedItems={this.state.meal}
                firebase={this.props.firebase}
                menuseason={this.props.menuseason}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withParams(MealPage);
