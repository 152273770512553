import React from 'react';
import { Link } from 'react-router-dom';
import { trackLink } from '../GlobalFunctions';

export const Unauthorized = (
  <div>
    <h1 id="heading">Unauthorized</h1>
    <p>Please sign in to view this page.</p>
    <Link
      onClick={() => trackLink('Unauthorized', 'Log In Button')}
      className="btn btn-alt"
      to="/login/"
    >
      Log In
    </Link>
    <Link
      onClick={() => trackLink('Unauthorized', 'Home Button')}
      className="btn btn-alt ml-10"
      to="/"
    >
      Back to Home
    </Link>
  </div>
);
