import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Constants from '../../Constants';
import {
  scrollToTop,
  trackCurrentPageView,
  updatePageMetadata
} from '../../GlobalFunctions';
import AdsenseAd from '../../components/AdsenseAd';

function BlogLandingPage() {
  useEffect(() => {
    updatePageMetadata('top-menu');
    scrollToTop();
    trackCurrentPageView();
  }, []);
  return (
    <div className="content-container container-padding min-content-height">
      <AdsenseAd className="mb-40" />
      <h1 className="mb-20">Top Menu Items</h1>
      <p>
        Having trouble deciding what to order from Honeygrow? Interested in
        trying something new and want some inspiration? Curious to see how your
        order stacks up to others?
      </p>
      <p>
        If so, take a look at some popular Honeygrow menu items and their
        nutritional info below!
      </p>
      <ul>
        <li>
          <Link to={Constants.SALAD_ROUTE}>Top 10 Salad Menu Items</Link>
        </li>
        <li>
          <Link to={Constants.STIRFRY_ROUTE}>Top 10 Stirfry Menu Items</Link>
        </li>
        <li>
          <Link to={Constants.HONEYBAR_ROUTE}>Top 10 Honeybar Menu Items</Link>
        </li>
        <li>
          <Link to={Constants.FEATURED_ROUTE}>Top 10 Featured Menu Items</Link>
        </li>
      </ul>
      <img alt="" src="/images/noodles2_large.jpg" />
      <h2 className="mt-40">How We Got This Info</h2>
      <p>
        This website is not officially affiliated with Honeygrow, so this is not
        an official list of their most popular menu items. This information is
        based on users of this unofficial Honeygrow nutritional calculator.
      </p>
      <p>
        This anonymous data was gathered through the site analytics and contains
        no personal information. None of your personal information will ever be
        shared or sold if you use this calculator or{' '}
        <Link to="../login/">sign up for an account</Link>. Read our official{' '}
        <Link to="../privacy/">Privacy Policy</Link> for more info.
      </p>
      <AdsenseAd className="mt-80" />
    </div>
  );
}

export default BlogLandingPage;
