import {
  faCheckCircle,
  faExclamationTriangle,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FirebaseApp } from 'firebase/app';
import {
  Auth,
  GoogleAuthProvider,
  User,
  browserLocalPersistence,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup
} from 'firebase/auth';
import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  errorMessage,
  trackError,
  trackEvent,
  trackLink
} from '../GlobalFunctions';
import EmailInput from './form/EmailInput';
import PasswordInput from './form/PasswordInput';

/**
 * The component properties
 */
interface LogInProps {
  /** The firebase app */
  readonly firebase: FirebaseApp;
  /** Whether the user is logged out */
  readonly isLoggedOut: boolean;
  /** The currently authenticated user */
  readonly user: User | null;
}

/**
 * The component state
 */
interface LogInState {
  email: string;
  forgotEmail: string;
  password: string;
  pwError: string;
  redirect: boolean;
  resetSubmitted: boolean;
  resetSuccess: boolean;
  refresh: boolean;
  showForgotModal: boolean;
}

class LogIn extends React.Component<LogInProps, LogInState> {
  readonly auth: Auth;

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      email: '',
      password: '',
      showForgotModal: false,
      forgotEmail: '',
      resetSubmitted: false,
      resetSuccess: false,
      refresh: false,
      pwError: ''
    };
    this.signInWithGoogle = this.signInWithGoogle.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleForgotEmailChange = this.handleForgotEmailChange.bind(this);
    this.handleForgotPasswordSubmit =
      this.handleForgotPasswordSubmit.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.auth = getAuth(props.firebase);
  }
  shouldComponentUpdate(nextProps, nextState) {
    return this.state !== nextState;
  }
  signInWithGoogle() {
    let cmp = this;
    trackLink('Login Module', 'Google Sign-In Button Clicked');
    setPersistence(this.auth, browserLocalPersistence)
      .then(function () {
        var provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        return signInWithPopup(cmp.auth, provider);
      })
      .then((fb) => {
        cmp.setState({
          redirect: true
        });
        trackLink('Login Module', 'Google Sign-In Success');
      })
      .catch(function (error) {
        console.error(error);
        if (error.code === 'auth/weak-password') {
          cmp.setState({ pwError: error.message });
        } else {
          trackError('Login Module', 'Google Sign-In Error - ' + error.message);
          document
            .getElementById('firebase-login-form')
            ?.appendChild(errorMessage(error));
        }
      });
  }
  handleForgotEmailChange(e) {
    this.setState({ forgotEmail: e.target.value });
  }
  handleEmailChange(emailInput) {
    // emailInput is a json object: { email: STRING, emailError: BOOLEAN}
    this.setState(emailInput);
  }
  handlePasswordChange(pwInput) {
    this.setState({ password: pwInput.password });
  }
  handleLoginSubmit(e) {
    e.preventDefault();
    let cmp = this;
    signInWithEmailAndPassword(this.auth, this.state.email, this.state.password)
      .then((data) => {
        cmp.setState({
          redirect: true
        });
        trackLink('Login Module', 'Email Sign-In');
      })
      .catch(function (error) {
        console.error(error);
        trackError('Login Module', 'Email Sign-In Error - ' + error.message);
        document
          .getElementById('firebase-login-form')
          ?.appendChild(errorMessage(error));
      });
  }
  handleForgotPasswordSubmit(e) {
    e.preventDefault();
    let email = this.state.forgotEmail;
    let cmp = this;
    sendPasswordResetEmail(this.auth, email)
      .then(function (res) {
        trackEvent('Login Module', 'Forgot Password Success');
        cmp.setState({
          resetSubmitted: true,
          resetSuccess: true
        });
      })
      .catch(function (error) {
        console.error(error);
        trackError('Login Module', 'Forgot Password Error - ' + error.message);
        document
          .getElementById('firebase-reset-pw-form')
          ?.appendChild(errorMessage(error));
      });
  }
  toggleModal() {
    if (this.state.resetSubmitted) {
      this.setState({
        refresh: true
      });
    }
    this.setState({
      showForgotModal: !this.state.showForgotModal
    });
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    } else if (this.state.refresh) {
      window.location.reload();
    }
    return (
      <div className="login-module">
        <form
          id="firebase-login-form"
          className="mb-20"
          onSubmit={this.handleLoginSubmit}
        >
          <EmailInput
            callback={this.handleEmailChange}
            user={this.props.user}
            id="login-email"
            nextFocus="login-password"
            validation={false}
          />
          <PasswordInput
            callback={this.handlePasswordChange}
            id="login-password"
            name="password"
            autocomplete="current-password"
            label="Password"
            errorMessage={this.state.pwError}
            validation={false}
          />
          <div className="forgot-password right">
            <button type="button" onClick={this.toggleModal}>
              Forgot password?
            </button>
          </div>
          <div className="center">
            <button
              id="firebase-login-submit"
              type="submit"
              className="btn btn-alt"
              disabled={this.props.isLoggedOut ? undefined : true}
            >
              Log In
            </button>
          </div>
        </form>

        <div className="center mt-20">
          <p>or</p>
          <button className="btn p-0" onClick={this.signInWithGoogle}>
            <img
              style={{ maxWidth: 200 }}
              alt="Sign In with Google"
              src="/images/icons/signin-google.png"
            />
          </button>
        </div>
        {this.state.showForgotModal && (
          <div className="modal-bg">
            <div className="modal-container reset-pw">
              <button className="btn-close" onClick={this.toggleModal}>
                <FontAwesomeIcon icon={faTimes} className="close-icon" />
                <span className="off-screen">Close modal</span>
              </button>
              <h2>Reset Password</h2>
              <p>
                Enter your account email address below and submit to be e-mailed
                a link to reset your password.
              </p>
              {!this.state.resetSubmitted && (
                <form
                  id="firebase-reset-pw-form"
                  onSubmit={this.handleForgotPasswordSubmit}
                >
                  <label htmlFor="forgot-email">Email</label>
                  <input
                    id="forgot-email"
                    name="email"
                    type="text"
                    value={this.state.forgotEmail}
                    onChange={this.handleForgotEmailChange}
                  />
                  <button
                    id="firebase-reset-pw-submit"
                    type="submit"
                    className="btn btn-alt"
                  >
                    Reset Password
                  </button>
                </form>
              )}
              {this.state.resetSubmitted && this.state.resetSuccess && (
                <div className="form-status mt-10">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="mb-10 blue-text icon"
                  />
                  Reset password link sent! Please check your email to update
                  your password.
                </div>
              )}
              {this.state.resetSubmitted && !this.state.resetSuccess && (
                <div className="form-status mt-10">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mb-10 red-text icon"
                  />
                  Oops, there was an error. Please refresh the page and try
                  again soon.
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LogIn;
