import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const passwordRegEx = /.{6}/; // One or more characters

interface CallbackProps {
  password: string;
  passwordError: boolean;
}

/**
 * The component properties
 */
interface PasswordInputProps {
  /** Hint for browser form autofill feature */
  readonly autocomplete: string;
  /** Optional callback function to call on input value change */
  readonly callback?: (value: CallbackProps) => void;
  /** An error message to display */
  readonly errorMessage: string;
  /** The id attribute for the input element */
  readonly id?: string;
  /** The label for the input element */
  readonly label: string;
  /** The name attribute for the input element */
  readonly name: string;
  /** A regex pattern to use for validation @default /.{6}/ */
  readonly regex?: RegExp;
  /** Whether to enable validation */
  readonly validation?: boolean;
}

/**
 * The component state
 */
interface PasswordInputState {
  /** Whether the password value is visible */
  isPeek: boolean;
  /** A regex pattern to use for validation */
  regex: RegExp;
  /** The current input value */
  password: string;
  /** Whether there's an error with the current input */
  passwordError: boolean | null;
}

class PasswordInput extends React.Component<
  PasswordInputProps,
  PasswordInputState
> {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordError: null,
      regex: this.props.regex || passwordRegEx,
      isPeek: false
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.togglePasswordPeek = this.togglePasswordPeek.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
  }
  handlePasswordChange(e) {
    let value = e.target.value; // New password
    //let isCurrentError = this.state.passwordError; // Current password
    let isNewValid = true;
    this.setState({ password: value });
    if (this.props.callback) {
      this.props.callback({ password: value, passwordError: !isNewValid });
    }
  }
  togglePasswordPeek(e) {
    this.setState({ isPeek: !this.state.isPeek });
  }
  isValidPassword(password) {
    return password.match(this.state.regex) !== null;
  }
  validatePassword(e) {
    this.setState({
      passwordError: !this.isValidPassword(this.state.password)
    });
  }
  render() {
    const pwError = this.props.errorMessage.length
      ? this.props.errorMessage
      : 'Password must be at least 6 characters.';
    const isError = this.state.passwordError || this.props.errorMessage !== '';
    return (
      <div>
        <label id={this.props.id + '-label'} htmlFor={this.props.id}>
          {this.props.label}
        </label>
        {this.props.validation !== false &&
          (this.state.passwordError !== null ||
            this.props.errorMessage !== '') && (
            <span
              id={this.props.id + '-status'}
              className="red-text ml-10 input-validation"
            >
              {isError ? (
                pwError
              ) : (
                <FontAwesomeIcon className="green-text" icon={faCheck} />
              )}
            </span>
          )}
        <div className="flex">
          <input
            id={this.props.id}
            name={this.props.name}
            required
            className="pw-input"
            type={this.state.isPeek ? 'text' : 'password'}
            onBlur={this.validatePassword}
            onChange={this.handlePasswordChange}
            autoComplete={this.props.autocomplete || 'current-password'}
            value={this.state.password}
          />
          <span className="center vertical-center pw-peek">
            <button type="button" onClick={this.togglePasswordPeek}>
              <FontAwesomeIcon icon={this.state.isPeek ? faEye : faEyeSlash} />
              <div className="off-screen">View Password</div>
            </button>
          </span>
        </div>
      </div>
    );
  }
}

export default PasswordInput;
