import React, { useEffect } from 'react';
import BlogPost from '../../components/BlogPost';
import {
  scrollToTop,
  updateMetadata,
  trackCurrentPageView
} from '../../GlobalFunctions';

function TopSaladItemsAugust2021() {
  const TITLE = 'Top Salad Menu Items';
  const DESCRIPTION =
    'The top salad menu items selected by users in this calculator in April 2024.';
  const IMAGE = 'https://www.hgnutrition.com/images/ogsalad.jpg';
  const CREATED = '2024-04-30T20:00:00-5:00';
  const MODIFIED = '2024-04-27T20:00:00-5:00';
  const IS_BLOG = true;

  useEffect(() => {
    updateMetadata(
      TITLE,
      DESCRIPTION,
      IMAGE,
      'index',
      CREATED,
      MODIFIED,
      IS_BLOG
    );
    scrollToTop();
    trackCurrentPageView();
  });

  return (
    <BlogPost title="Top 10 Salad Menu Items" timeframe="April 2024">
      <p>
        Although we don’t recommend putting all of these toppings onto one meal,
        it might be interesting to try a few combinations of these ingredients
        when ordering your next salad:
      </p>
      <ol>
        <li>
          <strong>All Natural Roasted Chicken</strong>: This is the most popular
          protein source for good reason. 110 calories provides you with 18
          grams of protein with only 1 gram of carbs— but the 840 grams of
          sodium might be concerning if you’re trying to watch your salt intake.
        </li>
        <li>
          <strong>Romaine Lettuce</strong>: You can’t have a salad without a
          good base, and romaine is by far the most popular choice for diners
          with an interest in nutrition. It’s only 25 calories per serving, and
          the high fiber content means it only contains 2 grams of net carbs.
        </li>
        <li>
          <strong>Cucumber</strong>: After protein and base, it’s time for
          add-ins. Out of all the options, cucumbers are the most popular.
          They’re pretty barren as far as nutrients are concerned, but their
          high water content and wonderful texture makes them a refreshing
          addition to a variety of salads.
        </li>
        <li>
          <strong>Green Goddess Dressing</strong>: Out of all the possible salad
          dressings, Green Goddess is the most popular. So long as you’re okay
          with salt, eggs, and milk, this is a delicious and low-calorie flavor
          booster that works great with a variety of salad bases and proteins.
        </li>
        <li>
          <strong>Roasted Broccoli</strong>: The second most popular add-in is
          roasted broccoli— which pairs excellently with roasted chicken. It has
          a lot more nutritional value than cucumber, but comes with a slightly
          higher carb count that can make it tricky to incorporate in a keto
          diet.
        </li>
        <li>
          <strong>Spring Mix</strong>: This base takes second place to romaine,
          but the net carbs and protein are virtually identical. If you don’t
          mind the slight increase in sodium, this is a good alternative to the
          most popular base that cuts out a few more calories from your salad.
        </li>
        <li>
          <strong>Grape Tomatoes</strong>: These tiny but flavorful fruits are
          the third most popular salad add-in and are packed with Vitamin A.
          They’re also very light on calories and carbs, which means they’re a
          guilt-free way to add a huge flavor boost to your salad.
        </li>
        <li>
          <strong>Avocado</strong>: This legendary superfood is the most popular
          salad topping— but keep in mind that Honeygrow incorporates some milk
          into their servings. Still, this is a delicious and filling topping
          that’s rich in both calories and fiber.
        </li>
        <li>
          <strong>Red Onions</strong>: The fourth most popular add-in is red
          onions, which has a pungent smell and taste that adds a huge kick to
          any dish. Like grape tomatoes, this is a low-calorie way to add more
          flavor to a salad, making for a great addition if you’re trying to
          avoid high-calorie dressings.
        </li>
        <li>
          <strong>Roasted Shrimp</strong>: Finally, roasted shrimp is the second
          most popular protein source for Honeygrow salads according to our
          users. So long as you’re comfortable eating seafood, this is one of
          the best ways to get a ton of protein with no carbs and a low calorie
          count.
        </li>
      </ol>
    </BlogPost>
  );
}

export default TopSaladItemsAugust2021;
